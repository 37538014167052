import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useToastStore = defineStore('toast', () => {
  const toasts = ref([]);
  const forceChange = ref([false, false, false]);

  /**
   * Add a toast to toasts list
   * @param {('info'|'success'|'warning'|'error')} type
   * @param {String} title
   * @param {Html} body
   * @param {Boolean} progress
   * @param {?{name: String, link}} action
   * @param {String} notificationType
   * @param {String} icon
   * @param {String} badge
   */

  // from quentin in bookletfront tools/useTools.vue
  const generateId = () => {
    // always start with a letter (for DOM friendlyness)
    let id = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      const asciiCode = Math.floor(Math.random() * 42 + 48);
      if (asciiCode < 58 || asciiCode > 64) {
        // exclude all chars between : (58) and @ (64)
        id += String.fromCharCode(asciiCode);
      }
    } while (id.length < 32);

    return id;
  };

  function addToast(
    type,
    title,
    body,
    progress = false,
    action = null,
    notificationType = '',
    icon = '',
    badge = '',
  ) {
    const id = generateId();
    toasts.value.push({
      id,
      type,
      title,
      body,
      progress,
      action,
      notificationType,
      icon,
      badge,
    });

    return id;
  }

  /**
   * Remove a toast from the toasts list depending on the index
   * @param {Number} idx
   */
  function removeToast(idx) {
    toasts.value.splice(idx, 1);
  }

  /**
   * Close a toast depending on title
   * If the toast is being displayed, remove it force close it
   * Otherwise, juste remove it
   * @param {String} id
   */
  function closeToast(id) {
    if (!toasts.value) {
      return;
    }
    for (let i = 0; i < 3; ++i) {
      if (toasts.value[i].id === id) {
        forceChange.value[i] = true;
        return;
      }
    }
    removeToast(toasts.value.findIndex((toast) => toast.id === id));
  }
  function resetToasts() {
    toasts.value = [];
  }

  return {
    toasts,
    forceChange,
    addToast,
    removeToast,
    closeToast,
    resetToasts,
  };
});
